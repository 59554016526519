@font-face {
	font-family: 'Gantari';
	src: local('Gantari'), url(./fonts/Gantari-Regular.ttf) format('truetype');
  }
  
  @font-face {
	  font-family: 'Gantari';
	  font-weight: 900;
	  src: local('Gantari'), url(./fonts/Gantari-Bold.ttf) format('truetype');
  }
  
  @font-face {
	  font-family: 'Gantari';
	  font-weight: 900;
	  src: local('Gantari'), url(./fonts/Gantari-Black.ttf) format('truetype');
  }
body {
  margin: 0;
  font-family: 'Gantari' !important;
  font-weight: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
