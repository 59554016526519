th,
td {
    text-align: left;
    padding: 4px;
}

table {
    border-collapse: collapse;
    color: #fff
}

th,
td {
    padding: 5px 8px;
    text-align: center;
    margin: 15px;
    margin-top: 100px;
}

tr:nth-child(even) {
    background-color: #fff;
    color: #777;
}

tr:nth-child(odd) {
    background-color: #777;
}

th:first-child,
td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

th:last-child,
td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}