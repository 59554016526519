:root {
    --primaryColor: #3478ee;
}

.section-full {
    background: #1c1c1c;
}

.headerItem {
    position: absolute;
    font-family: 'Gantari';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    color: #FFFFFF;
}

.allProducts {
    z-index: 5;
}




.half-ray-circle {
    width: 180px;
    aspect-ratio: 2/1;
    background:
        linear-gradient(-45deg, rgb(42, 77, 77, 0), rgba(167, 142, 184, 0.8), rgba(238, 64, 53, 0) 70%),
        linear-gradient(45deg, rgb(44, 75, 75), rgba(44, 75, 75, 0.8), rgba(44, 75, 75, 0) 70%),
        linear-gradient(-90deg, rgb(72, 92, 166), rgba(72, 92, 166, 0.8), rgba(72, 92, 166, 0) 70%),
        linear-gradient(135deg, rgb(44, 75, 75), rgba(42, 77, 77, 0.8), rgba(123, 192, 67, 0) 70%),
        linear-gradient(180deg, white, rgba(3, 146, 207, 0.8), rgba(3, 146, 207, 0) 70%);

    filter: blur(3px);
    margin-top: 1rem;
    margin-inline: auto;
    border-radius: 100px 100px 0 0;
}

.ray-contacts-left {
    position: fixed;
    top: 30%;
    left: 0;
    transform: rotate(90deg);
    margin-left: -45px;
}

.ray-contacts-right {
    position: fixed;
    top: 30%;
    right: 0;
    transform: rotate(-90deg);
    margin-right: -45px;
    z-index: -1;
}

.small-bullet {
    list-style-type: "\2022";
    /* Use a small bullet point */
    /* margin-left: 1em; */
    /* Add some left margin to the list */
}

.ul-footer-menu>li::before {
    list-style-type: "\2022" !important;
    content: "\2022" !important;
    padding: 0;
    margin: 0;
}

.address>li::before {
    list-style-type: none !important;
    content: none !important;
    padding: 0;
    margin: 0;
}

.product-item {
    width: 16rem;
    background: rgba(93, 94, 97, 0.61) !important;
    box-shadow: 0px 4px 38px 0px rgba(0, 0, 0, 0.39);

}

@media screen and (min-width: 1200px) {

    .product-list {
        display: grid;
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        max-width: 1400px;
        margin: 0 auto;
        gap: 20px;
    }
}



/*  ray Area */
.ray-product1 {
    position: absolute;
    top: 15rem;
    right: 5%;
    transform: translate(-10%, -50%);
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.212) 0%, rgba(91, 49, 190, 0.171) 50%);
    filter: blur(50px);
    z-index: -1;
}

.ray-product2 {
    position: absolute;
    top: 25rem;
    right: 25%;
    transform: translate(-10%, -60%);
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.212) 0%, rgba(49, 105, 190, 0.171) 50%);
    filter: blur(50px);
    z-index: -1;
}

.ray-product11 {
    top: 115rem;

}

.ray-product22 {
    top: 125rem;
}


.dlab-post-info {
    margin-inline: 5px;
    padding: 0 3px;
}

.ray-product-end {
    position: absolute;
    bottom: 5rem;
    left: 5%;
    transform: translate(-10%, -60%);
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.212) 0%, rgba(49, 105, 190, 0.171) 50%);
    filter: blur(50px);
    z-index: -1;

}

.services-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 100px;
    grid-row: 100px;
    z-index: 2;
}

.bg-contacts {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);
    margin-bottom: 50px;
    border-radius: 30px;
    color: #fff;
    z-index: 2;
}

.icon-content>h5 {
    color: #fff;
}

.icon-content>p {
    color: #fff;
    z-index: 2;
}


.contact-form {
    background-color: rgba(59, 51, 66, 0.4);
    backdrop-filter: blur(3px);
    border-radius: 35px;
    margin-bottom: 30px;
}


.border-table {
    border-radius: 25px;
    background-color: rgba(58, 73, 121, 0.4) !important;
    backdrop-filter: blur(1px) !important;
    /* border: 5px solid rgb(58, 73, 121); */
    margin: 25px;
    padding: 25px;
}

.requiredInput {
    border: none !important;
    border-radius: 35px !important;
    color: #fff !important;
    background-color: gray !important;
}

.dzMessage {
    border: none !important;
    border-radius: 20px !important;
    background-color: gray !important;
}


.site-button {
    background-color: #26479D !important;
    border-radius: 25px !important;
}

.contact-us-img {
    background-color: red;
    background-image: url('../../images/contact-us.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-width: 100px;
    min-height: 100px;
    border-radius: 0 35px 35px 0;
}

.dzMessage::placeholder {
    color: #fff !important;
}

.requiredInput::placeholder {
    color: #fff !important;
}

@media (max-width: 767px) {
    .services-cards {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
    }
}

.services-card {
    background: rgba(255, 255, 255, 0.26);
    border: 2px solid #fff;
    border-radius: 4.375rem;
    aspect-ratio: 1;
    max-width: 23rem;
    height: 23rem;
    padding: 10px;
    color: #fff
}

.services-card:hover {
    background-image: url('../../images/service-card.png');
    background-position: center;
    background-size: cover;
}

.img-shaiping {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    flex-direction: row;
    width: 100vw;
    height: 90vh;
    background-image: url('../../images/shipping-industry.jpg');
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
}

.element {
    color: "#fff";
    background-color: rgba(186, 85, 211, 0.3);
    backdrop-filter: blur(3px);
    height: 100vh;
    width: 100%;
    position: relative;
}


/* search button */
/* details button */
.details-button {
    background-image: url('../../images/btn-export/detailBtn.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-image: ; */
    color: #fff;
    font-size: 16px;
    padding: 24px;
    border: none;
    text-align: center;
    border-radius: 60px;
    position: relative;
}

/* search button */
/* 
.search_button {
    background:
        linear-gradient(-45deg, rgb(42, 77, 77, 0), rgba(167, 142, 184, 0.8), rgba(238, 64, 53, 0) 70%),
        linear-gradient(45deg, rgb(44, 75, 75), rgba(44, 75, 75, 0.8), rgba(44, 75, 75, 0) 70%),
        linear-gradient(-90deg, rgb(72, 92, 166), rgba(72, 92, 166, 0.8), rgba(72, 92, 166, 0) 70%),
        linear-gradient(135deg, rgb(44, 75, 75), rgba(42, 77, 77, 0.8), rgba(123, 192, 67, 0) 70%),
        linear-gradient(45deg, rgb(44, 75, 75), rgba(44, 75, 75, 0.8), rgba(44, 75, 75, 0) 70%),
        linear-gradient(180deg, rgb(74, 82, 192), rgba(3, 146, 207, 0.8), rgba(3, 146, 207, 0) 70%);

         */
.search_button {
    background-image: url('../../images/btn-export/searchBtn.svg');
    background-position: center;
    background-size: contain;
    /* background-image: ; */
    color: #fff;
    font-size: 16px;
    padding: 24px 48px;
    border: none;
    border-radius: 60px;
    position: relative;

}

.circle-search-button {
    position: fixed;
    background: radial-gradient(circle, rgba(255, 0, 255, 0.212) 0%, rgba(255, 255, 255, 0.171) 50%);
    filter: blur(1px);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.top-left-search-btn {
    top: -20px;
    left: 38px;
}

.service-box-2 {
    background: rgba(62, 64, 70, 1) !important;
    box-shadow: 0px 4px 38px 0px rgba(0, 0, 0, 0.39);
    border-radius: 3rem !important;
}

.product-img {
    aspect-ratio: 1 / 1;
    width: 30vh;
    padding: 0;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.bottom-left_search_btn {
    bottom: 8px;
    left: 8px;
}

.bottom-right_search_btn {
    bottom: -5px;
    right: 8px;
}

.headerLine {
    position: absolute;
    width: 90%;
    height: 3px;
    left: 100px;
    top: 122px;
    background: #FFFFFF;
}

.headerLineFatherDesktop {
    display: flex;
    width: 100%;
    /* justify-content: center; */
}


.headerLineFather {
    display: flex;
    width: 100%;
    display: none;
    /* justify-content: center; */
}

.loginBox {
    box-sizing: border-box;
    margin-top: 0.75rem;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: rgba(255, 255, 255, 0.44);
    border: 2px solid #FFFFFF;
    border-radius: 36px;
    display: flex;
    width: 8rem;
    height: 2rem;
    cursor: pointer;
}

.signinBox {
    box-sizing: border-box;
    margin-top: 0.75rem;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: rgba(255, 255, 255, 0.44);
    border: 2px solid #FFFFFF;
    border-radius: 36px;
    display: flex;
    width: 13rem;
    height: 2rem;
    cursor: pointer;
}

.loginSection {
    height: 27px;
    font-family: 'Gantari';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    color: #FFFFFF;
    display: flex;
    padding-inline: 10px;
    justify-content: center;
    align-items: center;
}

.signUpSection {
    height: 27px;
    font-family: 'Gantari';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    color: #FFFFFF;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginLine {
    width: 2px;
    height: 1.5rem;
    top: 97px;
    margin-top: 2px;
    background: #FFFFFF;
    transform: rotate(30deg);
}

.boxContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 3rem;
}

.innerContainer {
    display: flex;
    width: 90%;
}

.searchBoxDiv {
    height: 5rem;
    display: flex;
    flex-direction: column;
}

.phoneNumber {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
}

.searchBoxHeader {
    box-sizing: border-box;
    width: 276px;
    height: 2rem;
    left: 8rem;
    /* left: 407px;
    top: 28px; */
    background: rgba(255, 255, 255, 0.37);
    border: 2px solid #FFFFFF;
    border-radius: 36px;
    text-align: end;
    padding-right: 0.5rem;
}

.imgHeader {
    position: absolute;
    left: -15rem;
}

.searchInput {
    background-color: transparent;
    border: transparent;
    color: #FFFFFF;
    width: 85%;
    outline: 0;
}

.searchInput::placeholder {
    color: #FFFFFF
}

.searchInput:focus-visible {
    border: transparent;
}

.selectedHeader {
    border-bottom: 2px solid #fff;
    height: 3.7rem;
}

.hex {
    margin-top: 30px;
    width: 224px;
    height: 140px;
    background-color: rgba(255, 255, 255, 0.26);
    border-color: rgba(255, 255, 255, 0.26);
    position: relative;
    border-radius: 7px 7px 0px 0px;
    display: inline-block;
    z-index: 10;
    left: 23rem;
    top: 1.7rem;
}

.hex:after {
    content: "";
    width: 0;
    position: absolute;
    bottom: -95px;
    border-top: 95px solid;
    border-color: inherit;
    border-left: 112px solid transparent;
    border-right: 112px solid transparent;
    /* border-radius: 6px; */
    z-index: 10;
    left: 0rem;
    /* box-shadow: 0px 0px 3px 0px #ddd !important; */
}

.hexTablet {
    margin-top: 30px;
    width: 174px;
    height: 65px;
    background-color: rgba(255, 255, 255, 0.26);
    border-color: rgba(255, 255, 255, 0.26);
    position: relative;
    border-radius: 7px 7px 0px 0px;
    display: inline-block;
    z-index: 10;
    left: 22rem;
    top: -1.3rem;
}

.hexTablet:after {
    content: "";
    width: 0;
    position: absolute;
    bottom: -60px;
    border-top: 60px solid;
    border-color: inherit;
    border-left: 87px solid transparent;
    border-right: 87px solid transparent;
    z-index: 10;
    left: 0rem;
    /* box-shadow: 0px 0px 3px 0px #ddd !important; */
}

.hexMobile {
    margin-top: 30px;
    width: 175px;
    height: 65px;
    background-color: rgba(255, 255, 255, 0.26);
    border-color: rgba(255, 255, 255, 0.26);
    position: relative;
    border-radius: 7px 7px 0px 0px;
    display: inline-block;
    z-index: 10;
    left: 8rem;
    top: -1.3rem;
}

.hexMobile:after {
    content: "";
    width: 0;
    position: absolute;
    bottom: -60px;
    border-top: 60px solid;
    border-color: inherit;
    border-left: 87px solid transparent;
    border-right: 87px solid transparent;
    z-index: 10;
    left: 0rem;
}

.priceTableBackground {
    display: flex;
    justify-content: center;
    background-color: #f7f9fb;
    padding-bottom: 2rem;
    /* Background:url('../../images/newStyle/12.png'); */
    background-size: cover !important;
    background-position: center;
    width: 100vw;
    height: 130vh;
}

.priceTableBackgroundMobile {
    display: flex;
    justify-content: center;
    background-color: #f7f9fb;
    padding-bottom: 2rem;
    Background: url('../../images/newStyle/12.png');
    background-size: cover !important;
    background-position: center;
    width: 100vw;
    height: 96vh;
}

.priceTableBackgroundLittleMobile {
    display: flex;
    justify-content: center;
    background-color: #f7f9fb;
    padding-bottom: 2rem;
    Background: url('../../images/newStyle/12.png');
    background-size: cover !important;
    background-position: center;
    width: 100vw;
    height: 132vh;
}

.priceTableBackgroundTablet {
    display: flex;
    justify-content: center;
    background-color: #f7f9fb;
    padding-bottom: 2rem;
    Background: url('../../images/newStyle/12.png');
    background-size: cover !important;
    width: 100vw;
    height: 225vh;
    background-position: center;
}

.priceHeaderTR {
    padding: 3rem;
    height: 10rem;
    color: #efefef;
    vertical-align: bottom;
}

.PriceTableFooter {
    border-radius: 17px;
    background: rgb(40, 79, 193, 40%);
    color: #efefef;
}

.PriceTableRow:nth-child(even) {
    border-radius: 17px;
    background: rgba(255, 255, 255, 0.28);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.34);
    color: #efefef;
}

.PriceTableRow:nth-child(odd) {
    border-radius: 17px;
    background-color: rgba(255, 255, 255, 0.10);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.34);
    color: #efefef;

}

.PriceTableRow:nth-child(odd) {
    border-radius: 17px;
    background-color: rgba(255, 255, 255, 0.10);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.34);
    color: #efefef;

}

.tableBodyTD:first-child {
    padding: 1.2rem 2rem;
    text-align: center;
    border-radius: 1rem 0rem 0rem 1rem;
    width: fit-content;
}

.tableBodyTD:last-child {
    padding: 1.2rem 2rem;
    text-align: center;
    border-radius: 0rem 1rem 1rem 0rem;
    width: fit-content;
}

.tableBodyTD {
    padding: 1.2rem 2rem;
    text-align: center;
    width: fit-content;
}

.tableBodyTH:first-child {
    text-align: center;
    border-radius: 1rem 0rem 0rem 1rem;
    background: rgb(40, 79, 193, 40%);
    width: fit-content;

}

.tableBodyTH:last-child {
    text-align: center;
    border-radius: 0rem 1rem 1rem 0rem;
    background: rgb(40, 79, 193, 40%);
    width: fit-content;

}

.tableBodyTH {
    text-align: center;
    background: rgb(40, 79, 193, 40%);
    width: fit-content;

}

.aboutBtnStyle {
    background-image: url('../../images/msk/selectedImg.png');
    border: 1px solid #FFFFFF;
    background-position: center;
    border-radius: 48px;
    position: absolute;
    left: 8rem;
    margin-top: 30rem;
    color: #fff;
    padding: 0.3rem 2rem;
}

.aboutBtnStyleMobile {
    background-color: rgba(255, 255, 255, 0.27);
    border: 1px solid #FFFFFF;
    border-radius: 48px;
    position: absolute;
    right: 7rem;
    bottom: 0;
    font-size: 13px;
    margin-top: 14rem;
    color: #fff;
    padding: 0.1rem 1rem;
}

.aboutBtnStyleTablet {
    background-color: rgba(255, 255, 255, 0.27);
    border: 1px solid #FFFFFF;
    border-radius: 48px;
    position: absolute;
    right: 7rem;
    margin-top: 16.5rem;
    color: #fff;
    padding: 0.3rem 2rem;
}

.linkToPost {
    color: #fff;
    border-radius: 50px;
    background-color: #3475eea8;
    padding: 0.7rem 2rem;
    margin: 0.5rem 1rem;
    border: none;
    cursor: pointer !important;
}

.readMoreBlogbtn {
    background-image: url('../../images/msk/selectedImg.png');
    border: 1px solid #FFFFFF;
    background-position: center;
    border-radius: 48px;
    color: #fff;
    padding: 0.3rem 2rem;
}

.aboutStyle {
    Background: url('../../images/home-about.png');
    background-size: cover !important;
    width: 88vw;
    height: 90vh;
    margin-right: 6rem;
}

.aboutStyleMobile {
    Background: url('../../images/msk/aboutMS.png');
    background-size: cover !important;
    width: 100vw;
    height: 31vh;
    margin-bottom: 4rem;
}

.aboutStyleLittleMobile {
    Background: url('../../images/newStyle/aboutMobile.jpg');
    background-size: cover !important;
    width: 100vw;
    height: 40vh;
    margin-bottom: 4rem;
}

.blogBackGround {
    Background: url('../../images/newStyle/blogs.jpg');
    background-size: cover !important;
    width: 100vw;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c1c1c;
}

.blogBackGroundTablet {
    Background: url('../../images/newStyle/blogs.jpg');
    background-size: cover !important;
    width: 100vw;
    height: 190vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c1c1c;
    background-position: center;
}

.blogBackGroundMobile {
    Background: url('../../images/newStyle/blogs.jpg');
    background-size: cover !important;
    width: 100vw;
    height: 77vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c1c1c;
    background-position: center;
}

.blogBackGroundLittleMobile {
    Background: url('../../images/newStyle/blogs.jpg');
    background-size: cover !important;
    width: 100vw;
    height: 140vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c1c1c;
    background-position: center;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    height: 5rem;
    margin-top: -2rem;
}

.logoutContainer {
    display: flex;
    flex-direction: column;
    height: 5rem;
}

.whatsAppInFooter {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsAppInFooter:hover {
    background-color: green;
    transition: all 0.5s linear;
}

.selectCategoryImgContainer {
    position: relative;
    display: inline-block;

}

.selectCategoryImgOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 10px;
    color: #fff;
    text-align: center;
}

.blogContainer {
    margin-top: 8rem;
}

.blogTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem
}

.textInBlog {
    color: #efefef;
}

.blogListBackground {
    background-color: #c8c8c833;
    border-radius: 1.5rem;
}

.blogListBg {
    background-color: #c8c8c833;
    border-radius: 0.7rem;
}

.blogList {
    background-color: #1c1c1c;
}

@media screen and (max-width: 766px) {
    .dlab-bnr-inr-entry {
        padding-top: 0;
        margin-top: 0;
        height: 0px !important;
    }
}

.searchInputBlog {
    background-image: url('../../images/msk/selectedImg.png');
    border: transparent;
    background-position: center;
    color: #FFFFFF;
    width: 85%;
    outline: 0;
}

@media only screen and (min-width:600px) and (max-width:900px) {
    .table-container {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 1380px) {
    .imgHeader {
        position: absolute;
        left: 3rem;
    }

    .searchBoxHeader {
        display: none;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    animation: spinner 3s linear infinite;
}

.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
}

@media (min-height: 480px) {
    .table-container {
        width: 100%;
        overflow-x: auto;
        display: flex;
        justify-content: center;
    }

    .priceHeaderTR {
        padding: 3rem;
        height: 3rem;
        color: #efefef;
        vertical-align: center;
    }

    .PriceTableFooter {
        border-radius: 17px;
        background: rgb(40, 79, 193, 40%);
        color: #efefef;
    }

    .PriceTableRow:nth-child(even) {
        border-radius: 17px;
        background: rgba(255, 255, 255, 0.28);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.34);
        color: #efefef;
    }

    .PriceTableRow:nth-child(odd) {
        border-radius: 17px;
        background-color: rgba(255, 255, 255, 0.10);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.34);
        color: #efefef;

    }

    .PriceTableRow:nth-child(odd) {
        border-radius: 17px;
        background-color: rgba(255, 255, 255, 0.10);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.34);
        color: #efefef;

    }

    .tableBodyTD:first-child {
        padding: 1.2rem 2rem;
        text-align: center;
        border-radius: 1rem 0rem 0rem 1rem;
        width: fit-content;
    }

    .tableBodyTD:last-child {
        padding: 1.2rem 2rem;
        text-align: center;
        border-radius: 0rem 1rem 1rem 0rem;
        width: fit-content;
    }

    .tableBodyTD {
        padding: 1.2rem 2rem;
        text-align: center;
        width: fit-content;
    }

    .tableBodyTH:first-child {
        text-align: center;
        border-radius: 1rem 0rem 0rem 1rem;
        background: rgb(40, 79, 193, 40%);
        width: fit-content;

    }

    .tableBodyTH:last-child {
        text-align: center;
        border-radius: 0rem 1rem 1rem 0rem;
        background: rgb(40, 79, 193, 40%);
        width: fit-content;

    }

    .tableBodyTH {
        text-align: center;
        background: rgb(40, 79, 193, 40%);
        width: fit-content;

    }
}