:root {
	/* COLORS */
	--white: #e9e9e9;
	--gray: #333;
	--blue: #0367a6;
	--lightblue: #008997;

	/* RADII */
	--button-radius: 0.7rem;

	/* SIZES */
	--max-width: 758px;
	--max-height: 420px;

	font-size: 16px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.homebtn{
	position: fixed;
    right: 7.8rem;
    top: 3rem;
    background-color: transparent;
    width: 9rem;
    height: 9rem;
	cursor: pointer;
    border: none;
}
.homebtn:focus{
	outline: none;
}
.body {
	width: 100vw;
	height: 100vh;
	align-items: center;
	background-color: var(--white);
	background: url("../images/background/PRONIX-Stationery-Desktop-Wallpaper-HD-01-4-scaled.jpg");
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: grid;
	place-items: center;
}

.form__title {
	font-weight: 300;
	margin: 0;
	margin-bottom: 1.25rem;
}

.link {
	color: var(--gray);
	font-size: 0.9rem;
	margin: 1.5rem 0;
	text-decoration: none;
}
/* body{
	margin: 0px;
} */
.container1 {
	background-color: white;
	border-radius: var(--button-radius);
	box-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.25),
		0 0.7rem 0.7rem rgba(0, 0, 0, 0.22);
	/* height: var(--max-height);
	max-width: var(--max-width); */
	height:75%;
	overflow: hidden;
	/* position: relative; */
	/* width: 100%; */
	display: flex;
	align-items: center;
	display: contents;
}

.container__form {
	height: 75%;
	position: absolute;
	top: 5rem;
	transition: all 0.6s ease-in-out;
}

.container--signin {
	left: 0rem;
	width: 50%;
	z-index: 2;
}

.container1.right-panel-active .container--signin {
	transform: translateX(100%);
	display: none;
}

.container--signup {
	left: 0rem;
	opacity: 0;
	width: 50%;
	z-index: 1;
}

.container1.right-panel-active .container--signup {
	animation: show 0.6s;
	opacity: 1;
	transform: translateX(100%);
	z-index: 5;
}

.container__overlay_signup {
	height: 75%;
	left: 50%;
	overflow: hidden;
	position: absolute;
	top: 5rem;
	transition: transform 0.6s ease-in-out;
	width: 35%;
	z-index: 100;
	border-radius: 0.7rem 0 0 0.7rem;
}

.container__overlay_signin {
	height: 75%;
	left: 50%;
	overflow: hidden;
	position: absolute;
	top: 5rem;
	transition: transform 0.6s ease-in-out;
	width: 35%;
	z-index: 100;
	border-radius:  0 0.7rem 0.7rem 0 ;
	
}

.container1.right-panel-active .container__overlay_signin {
	transform: translateX(-100%);
}
.container1.right-panel-active .container__overlay_signup {
	transform: translateX(-100%);
}

.overlay {
	background-color: var(--lightblue);
	background: url("../images/background/PRONIX-Stationery-Desktop-Wallpaper-HD-01-4-scaled.jpg");
	/* background: url("https://res.cloudinary.com/dci1eujqw/image/upload/v1616769558/Codepen/waldemar-brandt-aThdSdgx0YM-unsplash_cnq4sb.jpg"); */
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	left: -100%;
	position: relative;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
	width: 200%;
	border-radius: 0.7rem;
}

.container1.right-panel-active .overlay {
	transform: translateX(50%);
}

.overlay__panel {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	position: absolute;
	text-align: center;
	top: 0rem;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
	width: 50%;
}

.overlay--left {
	transform: translateX(-20%);
}

.container1.right-panel-active .overlay--left {
	transform: translateX(0);
}

.overlay--right {
	right: 0;
	transform: translateX(0);
}

.container1.right-panel-active .overlay--right {
	transform: translateX(20%);
}

.btn {
	background-color: var(--blue);
	background-image: linear-gradient(90deg, var(--blue) 0%, var(--lightblue) 74%);
	border-radius: 20px;
	border: 1px solid var(--blue);
	color: var(--white);
	cursor: pointer;
	font-size: 0.8rem;
	font-weight: bold;
	letter-spacing: 0.1rem;
	padding: 0.9rem 4rem;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.form > .btn {
	margin-top: 1.5rem;
}

.btn:active {
	transform: scale(0.95);
}

.btn:focus {
	outline: none;
}

.form {
	background-color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 3rem;
	height: 100%;
	text-align: center;
	position:absolute;
	right: 0px;
	width: 55%;
	border-radius:  0.7rem 0 0 0.7rem ;
}
.myform {
	background-color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 3rem;
	height: 100%;
	text-align: center;
	border-radius: 0 0.7rem 0.7rem 0;
	width: 55%;
}

.input {
	background-color: #fff;
	border: none;
	padding: 0.9rem 0.9rem;
	margin: 0.5rem 0;
	width: 100%;
}

@keyframes show {
	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}



@media only screen and (max-width: 480px){
	.body {
		width: 100vw;
		height: 100vh;
		align-items: center;
		background-color: var(--white);
		background: url("../images/background/PRONIX-Stationery-Desktop-Wallpaper-HD-01-4-scaled.jpg");
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: grid;
		place-items: center;
	}
	.container--signup {
		left: 0rem;
		opacity: 0;
		width: 50%;
		z-index: 1;
	}
	
	.btn {
		background-color: var(--blue);
		background-image: linear-gradient(90deg, var(--blue) 0%, var(--lightblue) 74%);
		border-radius: 20px;
		border: 1px solid var(--blue);
		color: var(--white);
		cursor: pointer;
		font-size: 0.8rem;
		font-weight: bold;
		letter-spacing: 0.1rem;
		padding: 0.8rem 1.5rem;
		text-transform: uppercase;
		transition: transform 80ms ease-in;
	}
	.myform {
		background-color: var(--white);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 3rem;
		height: 80%;
		text-align: center;
		border-radius: 0 0.7rem 0.7rem 0;
		width: 98%;
	}
	.container__overlay_signup {
		height: 75%;
		left: 50%;
		overflow: hidden;
		position: absolute;
		top: 5rem;
		transition: transform 0.6s ease-in-out;
		width: 50%;
		z-index: 100;
		border-radius: 0.7rem 0 0 0.7rem;
	}
	.container__overlay_signin {
		height: 75%;
		left: 50%;
		overflow: hidden;
		position: absolute;
		top: 5rem;
		transition: transform 0.6s ease-in-out;
		width: 50%;
		z-index: 100;
		border-radius:  0 0.7rem 0.7rem 0 ;
	}
	.form {
		background-color: var(--white);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 3rem;
		height: 80%;
		text-align: center;
		position:absolute;
		right: 0px;
		width: 98%;
		border-radius:  0.7rem 0 0 0.7rem ;
	}
	.overlay {
		background-color: var(--lightblue);
		background: url("../images/background/PRONIX-Stationery-Desktop-Wallpaper-HD-01-4-scaled.jpg");
		/* background: url("https://res.cloudinary.com/dci1eujqw/image/upload/v1616769558/Codepen/waldemar-brandt-aThdSdgx0YM-unsplash_cnq4sb.jpg"); */
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 80%;
		left: -100%;
		position: relative;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
		width: 200%;
		border-radius: 0.7rem;
	}
	
	.form__title {
		font-weight: 300;
		margin: 0;
		width:10rem;
	}
	
}

@media only screen and (min-width:600px) and (max-width:900px){
	.container__form {
		height: 75%;
		position: absolute;
		transition: all 0.6s ease-in-out;
	}
	.form__title {
		font-weight: 300;
		margin: 0;
		width:10rem;
		margin-right: 3rem;
	}
	.link {
		color: var(--gray);
		font-size: 0.9rem;
		text-decoration: none;
		margin: 0rem;
	}
	.form > .btn {
		margin-top: 0rem;
	}
	.btn {
		background-color: var(--blue);
		background-image: linear-gradient(90deg, var(--blue) 0%, var(--lightblue) 74%);
		border-radius: 20px;
		border: 1px solid var(--blue);
		color: var(--white);
		cursor: pointer;
		padding: 0.5rem 1.8rem;
		font-size: 0.8rem;
		font-weight: bold;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		transition: transform 80ms ease-in;
	}
}