.MainTree{
    padding: 0;
    height:100vh;
    background: transparent;
}

.tree{
    padding: 0;
    /* background-image: url('./treebackground.jpg'); */
    background: none;
    padding:2rem 10rem;
    /* background-image: none; */
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: all-scroll !important;
}
#treeWrapper:before{
    content: "";
    background-color: rgba(255,255,255,0.9);
}
#treeWrapper{
    background-image: url('./treebackground1.jpg') !important;
}