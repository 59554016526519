
.slider-wrapper{
    position: relative;
    height: 70vh;
    overflow: hidden;
  }
  
.home-slider-7 .swiper-slide,
.home-slider2 .swiper-slide {
	height: 100vh;
  /* margin-top: 69px; */
	background-size: cover !important;
}
  
  .swiper-slide::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      /* background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
      background: rgba(0, 0, 0, 0.40); */
      bottom: 0;
      left: 0;
  }
  
  .previousButton, .nextButton {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
	background: url(../images/icon/angle_right.png) no-repeat center center / 32px;
	width: 60px;
	height: 60px;
	text-indent: -9999px;
	cursor: pointer;
	background-color: rgba(255,255,255,0.15);
  }
  
  .previousButton:hover, .nextButton:hover {
    background: url(../images/icon/angle_right.png) no-repeat center center / 32px;
	background-color: rgba(255,255,255,0.25);
  }
  
  .previousButton {
    left: 0;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
            transform: rotate(180deg) translateY(calc(50% + 0px));
  }
  
  .previousButton:hover {
    left: -10px;
  }
  
  .nextButton {
    right: 0;
  }
  
  .nextButton:hover {
    right: -10px;
  }
  
  .slider-content {
    text-align: center;
  }
  
  .slider-content .inner {
    padding: 0 70px 70px 70px; 
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    bottom:0;
    left: 0;
  }
  
  .slider-content .sliderStyle2  button{
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
  
  .slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 64px;
    line-height: 1;
  }
  
  .slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .slider-content section span {
    color: #FFFFFF;
  }
  
  .slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
  }
  
  .slider-content section img {
    width: 40px;
    height: 40px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
  }
  
  .slider-content section span strong {
    color: #FFFFFF;
    font-size: 14px;
    display: block;
  }
  .btnSliderSulfur{
    position: absolute;
    left: 79rem;
    bottom: 26rem;
    background-color: #c9c9c963 !important;
    border-radius: 50px;
    font-size: 1.2rem!important;
    border: 3px solid #fff !important;
  }
  .btnSliderBitumen {
    position: absolute;
    left: 79rem;
    bottom: 26rem;
    background-color: #c9c9c963 !important;
    border-radius: 50px;
    font-size: 1.2rem!important;
    border: 3px solid #fff !important;
}
  @media (max-height: 500px) {
    .slider-wrapper, .slide {
      height: calc(100vh - 75px);
    }    
    .btnSliderSulfur{
      position: absolute;
      left: 2.5rem;
      bottom: 11rem;
    }
    .btnSliderBitumen{
      position: absolute;
      left: 2.5rem;
      bottom: 11rem;
    }
    
  }
  @media only screen and (max-width: 500px) {
    .home-slider-7 .swiper-slide,
    .home-slider2 .swiper-slide {
      height: 25vh !important;
      /* margin-top: 69px; */
      background-size: cover !important;
    }
  }

  
  
  @media (max-width: 640px) {
    .slider-wrapper, .slide {
      height: calc(80vh - 75px);
    }    
    .btnSliderSulfur{
      position: absolute;
      left: 2.5rem;
      bottom: 0rem;
    }
    .btnSliderBitumen{
      position: absolute;
      left: 2.5rem;
      bottom: 0rem;
    }
  }
  
  @media (max-height: 600px) {
    .slider-content .inner h1 {
      font-size: 32px;
    }    
    .btnSliderSulfur{
      position: absolute;
      left: 2.5rem;
      bottom: 11rem;
    }
    .btnSliderBitumen{
      position: absolute;
      left: 2.5rem;
      bottom: 11rem;
    }
  }
  
  
  @media (max-height: 480px) {
    .slider-wrapper, .slide {
      height: calc(100vh - 75px);
    }    
    .btnSliderSulfur{
      position: absolute;
      left: 2.5rem;
      bottom: 4rem;
    }
    .btnSliderBitumen{
      position: absolute;
      left: 2.5rem;
      bottom: 4rem;
    }
  }